<template>
  <AGDialog
    v-model="show"
    :title="`${selectedRule ? 'Editar' : 'Adicionar'} regra de notificação`"
    @tertiaryButtonClick="close"
    @primaryButtonClick="save()"
  >
    <v-col cols="12">
      <SelectSimple
        id="cnpjs-field"
        v-model="params.cnpj"
        label="Empresa"
        :items="cnpjs"
        @change="habilitarCampos"
        @click="clearSelect"
      />
    </v-col>
    <v-col cols="12">
      <v-textarea
        id="term-field"
        v-model="params.term"
        :style="'color: var(--v-primary-base);'"
        maxlength="150"
        label="Trecho do assunto"
        auto-grown
        no-resize
        rows="4"
        row-height="30"
        :counter="150"
        minlength="1"
        dense
        outlined
        @input="habilitarCampos"
      />
    </v-col>
    <v-row class="ma-0">
      <v-col cols="10">
        <v-text-field
          id="idText"
          v-model="currentEmail"
          :style="'height:48px!important;max-height:48px!important;color: var(--v-primary-base);'"
          label="E-mail do destinatário"
          multiple
          dense
          outlined
          clearable
          :hint="`${totalEmails} /10 destinatários`"
          persistent-hint
          @keypress.enter="addEmail"
        />
      </v-col>

      <v-col cols="2">
        <v-btn id="add-email-btn" @click="addEmail">
          <v-icon x-large>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <div class="emails-list">
      <Tag v-for="(email, i) in params.emails" :key="i" small close @click:close="removeEmail(i)">
        {{ email }}
      </Tag>
    </div>
  </AGDialog>
</template>

<script>
import AdminServices from '@/services/adminServices.js';
import IntegraContadorService from '@/services/integraContadorService.js';
import UserService from '@/services/userService.js';
import cloneDeep from 'lodash/cloneDeep';

import { eventBus } from '../../../../main';

export default {
  components: {
    AGDialog: () => import('../../commons/AGDialog.vue'),
    Tag: () => import('../../commons/Tag.vue'),
    SelectSimple: () => import('../../commons/SelectSimple.vue'),
  },

  data() {
    return {
      currentUser: null,
      selectedRule: null,
      cnpjsCache: [],
      totalEmails: 0,
      currentEmail: null,

      valid: false,

      params: {
        id: null,
        cnpj: null,
        term: null,
        emails: [],
      },

      show: false,
    };
  },

  computed: {
    cnpjs() {
      const { cnpj } = this.params;
      return [{ itemTitle: cnpj, itemValue: cnpj }];
    },
  },

  mounted() {
    this.getCompanies();
  },

  created() {
    this.$on('upsert', this.upsert);
    this.$on('close', this.close);
  },

  methods: {
    clearSelect() {
      this.setParams();
    },

    upsert(rule) {
      this.selectedRule = cloneDeep(rule);
      this.setParams();
      this.show = true;
    },

    close() {
      this.show = false;
    },

    addEmail() {
      if (this.currentEmail && this.params.emails.length < 10) {
        this.params.emails.push(this.currentEmail);
        this.totalEmails++;
        this.currentEmail = '';
      }
      this.habilitarCampos();
    },

    removeEmail(index) {
      if (this.params.emails.length > 0) {
        this.params.emails.splice(index, 1);
        this.totalEmails--;
      }
      this.habilitarCampos();
    },

    async save() {
      if (this.params.emails.length < 1 || !this.params.cnpj || !this.params.term) {
        return;
      }
      this.params.cnpj = this.params.cnpj.split('/')[0].trim();
      await IntegraContadorService.rule.save(this.params);
      this.showToast('Regra de notificação automática alterada com sucesso!', 'success', 78);
      this.setParams();
      this.$root.$emit('refreshRules');
      this.close();
    },

    async getCompanies() {
      if (!this.currentUser) {
        let response = await UserService.getUser();
        this.currentUser = response.data;
      }
      let response = await AdminServices.userEstablishment.get(this.currentUser.id);
      this.companies = response.data;
      this.companies.forEach((company) => {
        this.cnpjsCache.push(company.establishment.details.cnpj + ' / ' + company.establishment.details.socialName);
      });
    },

    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },

    async setParams() {
      if (this.selectedRule) {
        this.params.id = this.selectedRule.id;
        this.params.cnpj = this.selectedRule.cnpj;
        this.params.term = this.selectedRule.term;
        this.params.emails = this.selectedRule.emails;
        this.totalEmails = this.params.emails.length;
      } else {
        this.params.cnpj = null;
        this.params.term = null;
        this.params.emails = [];
        this.totalEmails = 0;
      }
      this.cnpjsCache.forEach((cnpj) => {
        this.cnpjs.push({ itemTitle: cnpj, itemValue: cnpj });
      });
      this.currentEmail = null;
    },

    habilitarCampos() {
      this.valid = this.params.cnpj && this.params.term && this.params.emails.length >= 1;
    },
  },
};
</script>
<style>
.emails-list {
  margin-top: 16px;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
#new-rule-modal {
  background-color: white;
}
#term-field {
  color: var(--v-primary-base);
}
#add-email-btn {
  background-color: white;
  color: var(--v-primary-base);
  height: 48px !important;
}
#add-email-btn >>> .theme--dark.v-btn.v-btn--has-bg {
  background-color: white;
}
#email-title {
  background-color: #cdd0ed;
  padding: 4px;
  border-radius: 12px;
  margin-right: -4%;
}
#email-title p {
  font-size: 12px !important;
  margin-bottom: 0px !important;
}
#email-remove {
  background-color: #cdd0ed;
  border-radius: 92%;
  margin-right: 46%;
}
#idText {
  color: var(--v-primary-base);
}

.v-messages__message {
  /* display: none; */
  background: none !important;
  margin-left: -15px;
  text-align: start;
}
.v-text-field__slot {
  color: var(--v-primary-base);
}
#btnSalvar:disabled {
  background-color: #e7e7fa !important;
}
.v-label {
  color: var(--v-primary-base);
}
.v-label .input {
  color: var(--v-primary-base);
}
.v-select__selections .v-select__selection--comma {
  color: var(--v-primary-base);
}

#new-rule-modal label {
  color: var(--v-primary-base);
}

#new-rule-modal input {
  color: var(--v-primary-base);
}

#new-rule-modal textarea {
  color: var(--v-primary-base);
}

#cancel-btn :hover {
  background-color: #e3ffee !important;
  padding: 15% 25%;
  max-height: 90px;
  border-radius: 5px;
}

#save-btn :hover {
  background-color: #e3ffee !important;
  padding: 22% 39%;
  max-height: 90px;
  border-radius: 5px;
  color: var(--v-primary-base);
}
</style>
