var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AGDialog",
    {
      attrs: {
        title: `${
          _vm.selectedRule ? "Editar" : "Adicionar"
        } regra de notificação`,
      },
      on: {
        tertiaryButtonClick: _vm.close,
        primaryButtonClick: function ($event) {
          return _vm.save()
        },
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("SelectSimple", {
            attrs: { id: "cnpjs-field", label: "Empresa", items: _vm.cnpjs },
            on: { change: _vm.habilitarCampos, click: _vm.clearSelect },
            model: {
              value: _vm.params.cnpj,
              callback: function ($$v) {
                _vm.$set(_vm.params, "cnpj", $$v)
              },
              expression: "params.cnpj",
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("v-textarea", {
            style: "color: var(--v-primary-base);",
            attrs: {
              id: "term-field",
              maxlength: "150",
              label: "Trecho do assunto",
              "auto-grown": "",
              "no-resize": "",
              rows: "4",
              "row-height": "30",
              counter: 150,
              minlength: "1",
              dense: "",
              outlined: "",
            },
            on: { input: _vm.habilitarCampos },
            model: {
              value: _vm.params.term,
              callback: function ($$v) {
                _vm.$set(_vm.params, "term", $$v)
              },
              expression: "params.term",
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "ma-0" },
        [
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c("v-text-field", {
                style:
                  "height:48px!important;max-height:48px!important;color: var(--v-primary-base);",
                attrs: {
                  id: "idText",
                  label: "E-mail do destinatário",
                  multiple: "",
                  dense: "",
                  outlined: "",
                  clearable: "",
                  hint: `${_vm.totalEmails} /10 destinatários`,
                  "persistent-hint": "",
                },
                on: {
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.addEmail.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.currentEmail,
                  callback: function ($$v) {
                    _vm.currentEmail = $$v
                  },
                  expression: "currentEmail",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "v-btn",
                { attrs: { id: "add-email-btn" }, on: { click: _vm.addEmail } },
                [
                  _c("v-icon", { attrs: { "x-large": "" } }, [
                    _vm._v("mdi-plus"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "emails-list" },
        _vm._l(_vm.params.emails, function (email, i) {
          return _c(
            "Tag",
            {
              key: i,
              attrs: { small: "", close: "" },
              on: {
                "click:close": function ($event) {
                  return _vm.removeEmail(i)
                },
              },
            },
            [_vm._v(" " + _vm._s(email) + " ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }